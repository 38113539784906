import React, { useContext } from "react";
import { OidcConfigurationNameContext } from "./contexts/global/OidcConfigurationNameContext";
import { OidcSecure } from "@axa-fr/react-oidc";
import Chat from "./Chat";

export const OidcSecureChat = () => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  return (
    <OidcSecure configurationName={oidcConfigurationName}>
      <Chat />
    </OidcSecure>
  );
};
