import { ReactNode } from "react";
import { DarkModeEnabledContextProvider } from "./DarkModeContext";
import { OidcConfigListContextProvider } from "./OidcConfigListContext";
import { OidcConfigurationNameContextProvider } from "./OidcConfigurationNameContext";
import { AvailableModelsContextProvider } from "./AvailableModelsContext";
import { OidcSecureContextProvider } from "./OidcSecureContextProvider";

/**
 * Wrap children in context providers that are useful to the entire application, even before the user has signed in
 */
export const GlobalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <OidcConfigListContextProvider>
      <OidcConfigurationNameContextProvider>
        <OidcSecureContextProvider>
          <AvailableModelsContextProvider>
            <DarkModeEnabledContextProvider>
              {children}
            </DarkModeEnabledContextProvider>
          </AvailableModelsContextProvider>
        </OidcSecureContextProvider>
      </OidcConfigurationNameContextProvider>
    </OidcConfigListContextProvider>
  );
};
