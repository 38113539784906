import React from "react";
import ReactDOM from "react-dom/client";
import { init as initApm } from "@elastic/apm-rum";
import "./index.css";
import { GlobalContextProvider } from "./contexts/global/GlobalContextProvider";
import { AppContainer } from "./AppContainer";

const apm = initApm({
  serviceName: process.env.REACT_APP_APM_SERVICE_NAME as string,
  serverUrl: process.env.REACT_APP_APM_SERVER_URL as string,
  serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION as string,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <AppContainer />
    </GlobalContextProvider>
  </React.StrictMode>,
);
