import { Box, Button, CssBaseline, Dialog, Typography } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useSessionStorage from "./hooks/useSessionStorage";
import IOidcConfig from "./types/interfaces/IOidcConfig";
import { DarkModeContext } from "./contexts/global/DarkModeContext";
import { OidcConfigListContext } from "./contexts/global/OidcConfigListContext";
import { OidcConfigurationNameContext } from "./contexts/global/OidcConfigurationNameContext";

export default function LoginPage() {
  const { darkModeEnabled } = useContext(DarkModeContext);
  const oidcConfigList = useContext(OidcConfigListContext);
  const navigate = useNavigate();
  const { setOidcConfigurationName } = useContext(OidcConfigurationNameContext);
  console.log(oidcConfigList);
  return (
    <Fragment>
      <CssBaseline />
      <Dialog open={true} maxWidth="md" fullWidth={true}>
        <Box
          sx={{ minHeight: "50vh" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "1em" }}
          >
            <img
              src={darkModeEnabled ? "/logo-dark.png" : "/logo-light.png"}
              style={{ maxWidth: "60%" }}
            />
            <Typography variant="h5">Authenticate to Continue</Typography>
            {oidcConfigList.map((c) => (
              <Button
                variant="contained"
                onClick={() => {
                  setOidcConfigurationName(c.name);
                  navigate("/");
                }}
              >
                Continue with {c.name.toString()}
              </Button>
            ))}
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
}
