import { Chip } from "@mui/material";
import { Attachment } from "@mui/icons-material";

export type FileAttachmentProps = {
  fileName: string;
  onDeleteFile: () => void;
};

export default function FileAttachment({
  fileName,
  onDeleteFile,
}: FileAttachmentProps) {
  return (
    <Chip
      sx={{ maxWidth: 250 }}
      title={fileName}
      label={fileName}
      onDelete={onDeleteFile}
      icon={<Attachment />}
      variant="outlined"
    />
  );
}
