import { IconButton, Tooltip } from "@mui/material";
import { DarkMode, LightMode } from "@mui/icons-material";
import { DarkModeContext } from "../contexts/global/DarkModeContext";
import { useContext } from "react";

export default function DarkModeToggle() {
  const { darkModeEnabled, toggleDarkModeEnabled } =
    useContext(DarkModeContext);
  return (
    <Tooltip title={"Toggle Dark Mode"}>
      <IconButton onClick={toggleDarkModeEnabled}>
        {darkModeEnabled ? <LightMode /> : <DarkMode />}
      </IconButton>
    </Tooltip>
  );
}
