import IConversationInfo from "./types/interfaces/IConversationInfo";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { Box, CssBaseline, Drawer, useMediaQuery } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { v4 as uuid4 } from "uuid";
import ConversationList from "./components/ConversationList";
import ActiveConversationWindow from "./components/ActiveConversationWindow";
import IChatMessage from "./types/interfaces/IChatMessage";
import downloadText from "./utilities/downloadText";
import getDefaults from "./utilities/defaults";
import HelpDialog from "./components/HelpDialog";
import { db } from "./database/db";
import { useLiveQuery } from "dexie-react-hooks";
import { MessageRole } from "./types/enums/MessageRole";
import TopBar from "./components/TopBar";
import useLocalStorage from "./hooks/useLocalStorage";
import GlobalSettings from "./components/GlobalSettings";
import { OidcConfigurationNameContext } from "./contexts/global/OidcConfigurationNameContext";

export default function Chat() {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const [activeConversationObject, setActiveConversationObject] =
    useState<IConversationInfo | null>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [listOpen, setListOpen] = useState<boolean>(true);
  const [globalSettingsOpen, setGlobalSettingsOpen] = useState<boolean>(false);
  const [defaultSystemPrompt, setDefaultSystemPrompt] = useLocalStorage<string>(
    "defaultSystemPrompt",
    getDefaults().defaultSystemPrompt,
  );
  const [useRagByDefault, setUseRagByDefault] = useLocalStorage<boolean>(
    "useRagByDefault",
    getDefaults().useRagByDefault,
  );
  const oidcUser = useOidcUser(oidcConfigurationName);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const mobile = useMediaQuery("(max-width:600px)");

  // @ts-ignore
  const dbConversations = useLiveQuery<IConversationInfo[]>(() =>
    db.conversationInfo.orderBy("updated").reverse().toArray(),
  );

  async function addConversationDb() {
    let newId = uuid4().toString();
    try {
      await db.conversationInfo.add({
        id: newId,
        name: getDefaults().defaultConversationName,
        updated: new Date(),
        named: false,
      });
      const newSystemMessageId = uuid4().toString();
      await db.chatMessage.add({
        conversationId: newId,
        id: newSystemMessageId,
        content: getDefaults().defaultSystemPrompt,
        role: MessageRole.System,
        updated: new Date(),
        model: null,
        hasFeedback: false,
      });
      await selectConversation(newId);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async function () {
      const conversations = await db.conversationInfo.toArray();
      for (let conversation of conversations) {
        const messageCount = await db.chatMessage
          .where("conversationId")
          .equals(conversation.id)
          .count();

        if (messageCount < 2 && conversation.name === "New Conversation") {
          await db.conversationInfo
            .where("id")
            .equals(conversation.id)
            .delete();
        }
      }
    })();
    addConversationDb();
  }, []);

  async function editConversationDb(
    id: string,
    newName: string,
    updated: Date,
    named: boolean,
  ) {
    try {
      await db.conversationInfo.put(
        { id: id, name: newName, updated: updated, named: named },
        [id],
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteConversationDb(id: string) {
    try {
      if (activeConversationObject?.id === id) {
        await selectConversation(null);
      }
      await db.chatMessage
        .where("conversationId")
        .equals(id ?? "")
        .delete();
      await db.conversationInfo.delete(id);
    } catch (error) {
      console.log(error);
    }
  }

  function onMessageSending() {
    setIsSending(true);
  }

  function onMessageSent() {
    setIsSending(false);
  }

  function handleListToggle() {
    setListOpen((prev) => !prev);
  }

  async function selectConversation(id: string | null) {
    if (id === null || id === undefined) {
      setActiveConversationObject(null);
      return;
    }
    try {
      const currentConversation = await db.conversationInfo.get(id);
      if (currentConversation !== undefined) {
        setActiveConversationObject(currentConversation);
        if (mobile) {
          setListOpen(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleTranscriptDownloaded = (history: IChatMessage[]) => {
    const content = JSON.stringify(history, null, 2);
    downloadText(activeConversationObject?.name + ".json", content);
  };

  const handleOpenHelp = () => {
    setHelpOpen(true);
  };

  const handleCloseHelp = () => {
    setHelpOpen(false);
  };

  const clearSending = () => {
    setIsSending(false);
  };

  const handleGlobalSettingsOpen = () => {
    setGlobalSettingsOpen(true);
  };

  const handleGlobalSettingsClose = () => {
    setGlobalSettingsOpen(false);
  };

  const handleGlobalSettingsSave = (
    newDefaultSystemPrompt: string,
    newUseRagByDefault: boolean,
  ) => {
    setDefaultSystemPrompt(newDefaultSystemPrompt);
    setUseRagByDefault(newUseRagByDefault);
  };

  const drawerWidth = 290;
  return (
    <Fragment>
      <HelpDialog open={helpOpen} onClose={handleCloseHelp} />
      <GlobalSettings
        open={globalSettingsOpen}
        currentDefaultSystemPrompt={defaultSystemPrompt}
        currentUseRagByDefault={useRagByDefault}
        onSave={handleGlobalSettingsSave}
        onClose={handleGlobalSettingsClose}
      />
      <Box>
        <Box>
          <CssBaseline />
          <TopBar
            mobile={mobile}
            menuOpen={listOpen}
            userName={
              oidcUser.oidcUser?.name ??
              oidcAccessToken.accessTokenPayload?.name
            }
            roles={
              oidcUser.oidcUser?.groups ??
              oidcAccessToken.accessTokenPayload?.groups
            }
            onHelpOpened={handleOpenHelp}
            onListToggle={handleListToggle}
            onGlobalSettingsOpened={handleGlobalSettingsOpen}
          />
          <Drawer
            sx={{
              width: mobile ? "100vw" : drawerWidth,
              "& .MuiDrawer-paper": {
                width: mobile ? "100vw" : drawerWidth,
                boxSizing: "border-box",
                overflowX: "hidden",
              },
            }}
            open={listOpen}
            variant="persistent"
            anchor="left"
          >
            <ConversationList
              mobile={mobile}
              userName={
                oidcUser.oidcUser?.name ??
                oidcAccessToken.accessTokenPayload?.name
              }
              roles={
                oidcUser.oidcUser?.groups ??
                oidcAccessToken.accessTokenPayload?.groups
              }
              onHelpOpened={handleOpenHelp}
              conversationInfoList={dbConversations}
              onConversationChanged={editConversationDb}
              onConversationDeleted={deleteConversationDb}
              onConversationAdded={addConversationDb}
              onConversationClicked={selectConversation}
              activeId={activeConversationObject?.id}
              isSending={isSending}
              onGlobalSettingsOpened={handleGlobalSettingsOpen}
            />
          </Drawer>
          <Box
            component="main"
            sx={{
              ml: mobile ? 0 : listOpen ? `${drawerWidth}px` : 0,
              mt: 0,
              mb: 0,
              mr: 0,
              width: mobile
                ? "100%"
                : listOpen
                  ? `calc(100% - ${drawerWidth}px)`
                  : "100%",
            }}
          >
            <ActiveConversationWindow
              conversationId={activeConversationObject?.id}
              isSending={isSending}
              onMessageSending={onMessageSending}
              onMessageSent={onMessageSent}
              onTranscriptDownloaded={handleTranscriptDownloaded}
              clearSending={clearSending}
              defaultSystemPrompt={defaultSystemPrompt}
              useRagByDefault={useRagByDefault}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
