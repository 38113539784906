import {Dialog, Link} from "@mui/material";

export type HelpDialogProps = {
    open: boolean,
    onClose: () => void;
}

export default function HelpDialog({open, onClose}: HelpDialogProps) {
    return (<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <div style={{padding: "2em", margin: 0}}>
            <h3 style={{marginTop: 0}}>Welcome to Expedient - AI CTRL Chat</h3>

            <p>This is the basic chat interface provided for interacting with the Expedient AI Gateway.  You can use this application to interact with any of the supported models your AI administrator has provisioned for you to access.  A few additional notes on getting the most out of this interface and your AI gateway are below.</p>


            <ul>
                <li>The hamburger menu next to the "Write a message" field will allow you to upload files, download a conversation transcript, or adjust your conversation settings, which includes both the AI model you're interacting with, and the current system prompt</li>
                <li>You can also set the default system prompt for all new conversations by clicking on the gear icon in the upper right corner of the screen.  This will not impact any current conversations</li>
                <li>currently, conversations are stored locally, so if you move to another computer or clear your browsers cache your conversation history will be lost</li>
            </ul>
        </div>

    </Dialog>)
}