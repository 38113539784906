import { Box, CssBaseline, Dialog } from "@mui/material";
import React, { useContext } from "react";
import { DarkModeContext } from "./contexts/global/DarkModeContext";

export const Loading = () => {
  const { darkModeEnabled } = useContext(DarkModeContext);
  return (
    <html style={{ backgroundColor: darkModeEnabled ? "white" : "black" }}>
      <body>
        <CssBaseline />
        Loading OIDC configurations and LLMs from LLMGateway
      </body>
    </html>
  );
};
