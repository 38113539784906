import IChatMessage from "../types/interfaces/IChatMessage";
import { Typography, Box, Alert } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CodeBlock from "./CodeBlock";
import { useContext } from "react";
import { UserContext } from "./ActiveConversationWindow";
import ChatImage from "./ChatImage";

export type ChatMessageProps = {
  message: IChatMessage;
  fromUser: boolean;
};

export default function ChatMessage({ message, fromUser }: ChatMessageProps) {
  const userName = useContext(UserContext);

  let splitMessage = message.content.split("===== ATTACHED FILE BELOW======");
  let cleanedMessage = splitMessage[0];
  let attachedFilesContent = splitMessage.slice(1);

  let attachmentElements = attachedFilesContent.map((fileContent, index) => (
    <details key={index}>
      <Typography variant="overline" component="summary">
        Plain Text from Attached File {index + 1}
      </Typography>
      {fileContent}
    </details>
  ));

  let bodyElement = <Alert severity="info">This message is blank.</Alert>;
  if (cleanedMessage.startsWith("Something went wrong: ")) {
    bodyElement = <Alert severity="error">{cleanedMessage}</Alert>;
  } else if (cleanedMessage.length > 100_000) {
    bodyElement = <p>{cleanedMessage}</p>;
  } else if (cleanedMessage !== "") {
    bodyElement = (
      <ReactMarkdown
        className="markdown"
        remarkPlugins={[remarkGfm]}
        components={{
          // @ts-ignore
          pre: CodeBlock,
          img: ChatImage,
          a: ({ ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          ),
        }}
      >
        {cleanedMessage}
      </ReactMarkdown>
    );
  }

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="button" color="primary">
        {fromUser
          ? userName.userName
          : message.model?.display_name ?? "Assistant"}
      </Typography>
      <Typography variant="body1" component={"span"}>
        {bodyElement}
        {attachmentElements}
      </Typography>
    </Box>
  );
}
