import { createContext, ReactNode, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useLocalStorage from "../../hooks/useLocalStorage";

export const DarkModeContext = createContext<{
  darkModeEnabled: boolean;
  toggleDarkModeEnabled: () => void;
}>({ darkModeEnabled: true, toggleDarkModeEnabled: () => {} });

/**
 * Perform initialization of things related to Themes and dark mode. Wrap children in a context provider that allows
 * them to consume and update the settings related to dark mode
 */
export const DarkModeEnabledContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [darkModeEnabledPreference, setDarkModeEnabledPreference] =
    useLocalStorage<boolean | null>("darkModeEnabledPreference", null);

  const [darkModeEnabled, setDarkModeEnabled] = useState<boolean>(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  useEffect(() => {
    const r: HTMLElement | null = document.querySelector(":root");
    r?.style.setProperty(
      "--primary-color",
      process.env.REACT_APP_PRIMARY_COLOR ?? "#CC0000",
    );
    document.title = "Expedient Chat";
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: darkModeEnabledPreference ?? darkModeEnabled ? "dark" : "light",
      primary: {
        main: process.env.REACT_APP_PRIMARY_COLOR ?? "#CC0000",
      },
      secondary: {
        main: "#E0C2FF",
        light: "#F5EBFF",
        contrastText: "#47008F",
      },
    },
  });

  const toggleDarkModeEnabled = () => {
    setDarkModeEnabledPreference((prev) => !(prev ?? darkModeEnabled));
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <DarkModeContext.Provider
        value={{
          darkModeEnabled: darkModeEnabledPreference ?? darkModeEnabled,
          toggleDarkModeEnabled: toggleDarkModeEnabled,
        }}
      >
        {children}
      </DarkModeContext.Provider>
    </ThemeProvider>
  );
};
