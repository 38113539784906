import { createContext, ReactNode } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";

export const OidcConfigurationNameContext = createContext<{
  oidcConfigurationName: string;
  clearOidcConfigurationName: () => void;
  setOidcConfigurationName: (newName: string) => void;
}>({
  oidcConfigurationName: "default",
  clearOidcConfigurationName: () => {},
  setOidcConfigurationName: (_) => {},
});

export const OidcConfigurationNameContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [oidcConfigurationName, setOidcConfigurationName] =
    useLocalStorage<string>("configurationName", "default");
  const clearAuthProvider = () => {
    setOidcConfigurationName("default");
  };

  return (
    <OidcConfigurationNameContext.Provider
      value={{
        oidcConfigurationName: oidcConfigurationName,
        clearOidcConfigurationName: clearAuthProvider,
        setOidcConfigurationName: setOidcConfigurationName,
      }}
    >
      {children}
    </OidcConfigurationNameContext.Provider>
  );
};
