import IChatMessage from "../types/interfaces/IChatMessage";
import IModel from "../types/enums/IModel";
import IGatewayAPIRequest from "../types/interfaces/IGatewayAPIRequest";
import { AuthProvider } from "../types/enums/AuthProvider";
import convertGatewayRequest from "./convertGatewayRequest";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import trimConversationHistory from "./trimConversationHistory";
import cleanMessages from "./cleanMessages";
import IGatewayOidcConfig from "../types/interfaces/IGatewayOidcConfig";
import ILargeLanguageModel from "../types/interfaces/ILargeLanguageModel";

interface FetchPromiseWithAbort extends Promise<Response> {
  abort: AbortController;
}

interface FeedbackModel {
  message_id: string;
  feedback: boolean;
  details?: string;
}

export default function sendGatewayRequest(
  chatHistory: IChatMessage[],
  model: IModel,
  accessToken: OidcAccessToken,
  rag: boolean = false,
  pii: boolean = false,
  popupCallback: (s: string) => void = () => {},
): FetchPromiseWithAbort {
  // Use the extended type here
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const abortController = new AbortController();
  const trimmedHistory = trimConversationHistory(
    chatHistory,
    model,
    popupCallback,
  );
  const gateway_api_request: IGatewayAPIRequest = {
    messages: cleanMessages(trimmedHistory),
    model: model.internal_name,
    rag: rag,
    stream: true,
    authProvider: AuthProvider.OneLogin,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  // Create an instance of AbortController
  const { signal } = abortController;

  // Fetch request with signal option
  const fetchPromise = fetch(`${host}/chat/completions`, {
    method: "POST",
    body: JSON.stringify(convertGatewayRequest(gateway_api_request)),
    headers: headers,
    signal, // Pass signal option
  }) as FetchPromiseWithAbort; // Cast to the extended type

  // Attach abort function to the returned promise
  fetchPromise.abort = abortController;
  if (trimmedHistory.length < 2) {
    fetchPromise.abort.abort(
      "Something went wrong: The message exceeded the context window. Please send a shorter message or consider selecting a model with a larger context window.",
    );
  }
  return fetchPromise;
}

export async function getAllModels(): Promise<Array<ILargeLanguageModel>> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  let resp = await fetch(`${host}/models/all`, {
    method: "GET",
    headers: headers,
  });
  return (await resp.json()).models;
}

export async function getOidcConfigurations(): Promise<
  Array<IGatewayOidcConfig>
> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  let resp = await fetch(`${host}/oidc/config`, {
    method: "GET",
    headers: headers,
  });
  let returnVal = await resp.json();
  return returnVal.configs.filter(
    (c: IGatewayOidcConfig) => typeof c.name === "string",
  );
}

export async function nameConversation(
  chatHistory: IChatMessage[],
  model: IModel,
  defaultModel: IModel,
  accessToken: OidcAccessToken,
) {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  let textModel = model;
  if (model.image_model) {
    textModel = defaultModel;
  }
  const gateway_api_request: IGatewayAPIRequest = {
    messages: chatHistory,
    model: textModel.internal_name,
    rag: false,
    stream: false,
    authProvider: AuthProvider.OneLogin,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };
  return await fetch(`${host}/name_conversation`, {
    method: "POST",
    body: JSON.stringify(convertGatewayRequest(gateway_api_request)),
    headers: headers,
  });
}

export async function handleFeedback(
  messageID: string,
  feedback: boolean,
  details: string,
  accessToken: OidcAccessToken,
) {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const feedbackData: FeedbackModel = {
    message_id: messageID,
    feedback: feedback,
    details: details,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  let resp = await fetch(`${host}/provide_feedback`, {
    method: "POST",
    body: JSON.stringify(feedbackData),
    headers: headers,
  });
  console.log(resp);
  return resp;
}
