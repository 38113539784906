import { createContext, ReactNode, useEffect } from "react";
import IOidcConfig from "../../types/interfaces/IOidcConfig";
import ILargeLanguageModel from "../../types/interfaces/ILargeLanguageModel";
import useSessionStorage from "../../hooks/useSessionStorage";
import { getOidcConfigurations } from "../../utilities/sendGatewayRequest";

export const OidcConfigListContext = createContext<IOidcConfig[]>([]);

export const OidcConfigListContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [oidcConfigList, setOidcConfigList] = useSessionStorage<IOidcConfig[]>(
    "oidcConfigList",
    [],
  );

  useEffect(() => {
    (async () => {
      const oidcConfigResponse = await getOidcConfigurations();
      setOidcConfigList((prev) =>
        oidcConfigResponse.map((c) => {
          return {
            name: c.name,
            client_id: c.client_id,
            redirect_uri: window.location.origin + c.redirect_uri,
            silent_redirect_uri: window.location.origin + c.silent_redirect_uri,
            scope: c.scope,
            authority: c.authority,
            service_worker_only: false,
            demonstrating_proof_of_possession: false,
          };
        }),
      );
    })();
  }, [setOidcConfigList]);

  return (
    <OidcConfigListContext.Provider value={oidcConfigList}>
      {children}
    </OidcConfigListContext.Provider>
  );
};
