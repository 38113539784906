import { createContext, ReactNode, useEffect, useState } from "react";
import ILargeLanguageModel from "../../types/interfaces/ILargeLanguageModel";
import { getAllModels } from "../../utilities/sendGatewayRequest";

export const AvailableModelsContext = createContext<Array<ILargeLanguageModel>>(
  [],
);

export const AvailableModelsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [availableModels, setAvailableModels] = useState<ILargeLanguageModel[]>(
    [],
  );
  useEffect(() => {
    (async () => {
      const availableModelsResponse = await getAllModels();
      setAvailableModels(availableModelsResponse);
    })();
  }, []);

  return (
    <AvailableModelsContext.Provider value={availableModels}>
      {children}
    </AvailableModelsContext.Provider>
  );
};
